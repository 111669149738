import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { SURVEY } from '../constants/resourceId';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.surveyId === SURVEY.SETTING_INCOMPLETE) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.SETTING_INCOMPLETE,
          operationCycle: '運用設定名1',
          type: 'ビジネススタンスサーベイ',
          typeCode: 'business_attitudes',
          status: '設定未完了',
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2023-03-10T18:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.SETTING_COMPLETE) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.SETTING_COMPLETE,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '設定完了',
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2023-03-10T18:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.IN_PROGRESS) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.IN_PROGRESS,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '回答中',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.BEFORE_CALCULATE) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.BEFORE_CALCULATE,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '集計前',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.CALCULATING) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.CALCULATING,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '集計中',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.COMPLETE) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.COMPLETE,
          operationCycle: 'シナリオテスト用',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '終了',
          startedAt: '2023-01-08T17:00:00+09:00',
          endedAt: '2023-01-15T17:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.CALCULATED_UNEXISTS_SUMMARY_ZIP) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.CALCULATED_UNEXISTS_SUMMARY_ZIP,
          operationCycle: 'シナリオテスト用',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '集計完了',
          startedAt: '2023-01-08T17:00:00+09:00',
          endedAt: '2023-01-15T17:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.CALCULATED_EXISTS_SUMMARY_ZIP) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.CALCULATED_EXISTS_SUMMARY_ZIP,
          operationCycle: 'シナリオテスト用',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '集計完了',
          startedAt: '2023-01-08T17:00:00+09:00',
          endedAt: '2023-01-15T17:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  // surveyConfigの分岐に合わせてこちらでも定義する(8~10)
  if (params.surveyId === SURVEY.SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_ERROR) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_ERROR,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '回答中',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_WARNING) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_WARNING,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '設定未完了',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.SETTING_INPROGRESS_FOR_CONFIG_DISCARD_ERROR) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.SETTING_INPROGRESS_FOR_CONFIG_DISCARD_ERROR,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '集計中',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.INPROGRESS_FOR_RESEND_MAIL) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.INPROGRESS_FOR_RESEND_MAIL,
          operationCycle: '運用設定名1',
          type: 'マネジメントサーベイ',
          typeCode: 'management',
          status: '回答中',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.IN_PROGRESS_BUSINESS_ATTITUDES) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.IN_PROGRESS_BUSINESS_ATTITUDES,
          operationCycle: '運用設定名13',
          type: 'ビジネススタンスサーベイ',
          typeCode: 'business_attitudes',
          status: '回答中',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.BEFORE_START_ANSWER_FULL_ADDITIONAL_QUESTION) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.BEFORE_START_ANSWER_FULL_ADDITIONAL_QUESTION,
          operationCycle: '回答開始前追加設問最大',
          type: 'ビジネススタンスサーベイ',
          typeCode: 'business_attitudes',
          status: '設定完了',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.AFTER_START_ANSWER_FULL_ADDITIONAL_QUESTION) {
    return HttpResponse.json(
      {
        survey: {
          id: SURVEY.AFTER_START_ANSWER_FULL_ADDITIONAL_QUESTION,
          operationCycle: '回答開始後追加設問最大',
          type: 'ビジネススタンスサーベイ',
          typeCode: 'business_attitudes',
          status: '回答中',
          startedAt: '2023-02-21T14:00:00+09:00',
          endedAt: '2023-02-28T14:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      survey: {
        id: 1,
        operationCycle: '運用設定名1',
        type: 'マネジメントサーベイ',
        typeCode: 'management',
        status: '設定未完了',
        startedAt: '2023-03-06T10:00:00+09:00',
        endedAt: '2023-03-10T18:00:00+09:00',
      },
    },
    { status: 200 }
  );
};

export const survey = {
  get,
};
