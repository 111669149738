import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { longMailAddress, longUserName, longEnglishUserName } from '../utilData';

const EMPTY_CASE = '2';

const BOSS_EMPTY_CASE = '3';
const STAFF_EMPTY_CASE = '4';

const get: GetResponseResolver = async ({ params, request }) => {
  const url = new URL(request.url);
  const answererType = url.searchParams.get('answerer_type');

  if (params.companyUserId === EMPTY_CASE) {
    return HttpResponse.json(
      {
        answerers: [],
      },
      { status: 200 }
    );
  }

  if (params.companyUserId === BOSS_EMPTY_CASE && answererType === 'boss') {
    return HttpResponse.json(
      {
        answerers: [],
      },
      { status: 200 }
    );
  }

  if (params.companyUserId === STAFF_EMPTY_CASE && answererType === 'staff') {
    return HttpResponse.json(
      {
        answerers: [],
      },
      { status: 200 }
    );
  }

  const answerers = [
    {
      companyUserId: 1,
      name: '長いメールアドレス',
      email: longMailAddress,
    },
    {
      companyUserId: 1,
      name: longEnglishUserName,
      email: 'long@example.com',
    },
    {
      companyUserId: 1,
      name: longUserName,
      email: longMailAddress,
    },
    {
      companyUserId: 1,
      name: '細貝怜加',
      email: 'reirei@example.com',
    },
    {
      companyUserId: 2,
      name: '溝上雄太',
      email: 'mizo@example.com',
    },
    {
      companyUserId: 3,
      name: '亀山航太',
      email: 'kameyama@example.com',
    },
  ];

  Array.from({ length: 1000 }, (_, index) =>
    answerers.push({
      companyUserId: index,
      name: `${answererType === 'boss' ? '上司' : '部下'}${index + 1}`,
      email: `${answererType}${index + 1}@example.com`,
    })
  );

  answerers.forEach((a, index) => (a.companyUserId = index));

  return HttpResponse.json({
    answerers,
  });
};

export const surveyQuestionnaireSettingAnswerTargetUsersAnswererCandidates = {
  get,
};
