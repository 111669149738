import axios, { type AxiosResponse } from 'axios';
import { handleError } from '../ErrorHandlers/defaultErrorHandler';
import type { ErrorHandlerOption } from '../ErrorHandlers/defaultErrorHandler';
import { VITE_APP_API_BASE_URL } from '~operation/env';

const baseDomain = VITE_APP_API_BASE_URL;
const baseURL = `${baseDomain}/v1`;

export default class {
  private static _axiosInstance = this.initializeAxios();

  static async get<T>(url: string, params?: unknown, options: ErrorHandlerOption = {}) {
    const { shouldShowErrorPage = true, shouldShowErrorToast = true } = options;

    return this._axiosInstance
      .get<T>(url, { params })
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, { shouldShowErrorPage, shouldShowErrorToast });
      });
  }

  static async fileDownload<T>(url: string, params?: unknown, options: ErrorHandlerOption = {}) {
    const { shouldShowErrorPage = false, shouldShowErrorToast = true } = options;

    return this._axiosInstance
      .get<T>(url, { params, responseType: 'blob' })
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, { shouldShowErrorPage, shouldShowErrorToast });
      });
  }

  static initializeAxios() {
    const axiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        'content-type': 'application/json',
      },
      withCredentials: true,
    });

    return axiosInstance;
  }
}
