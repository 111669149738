import { HttpResponse } from 'msw';
import type { GetResponseResolver, PostResponseResolver, DeleteResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { longMailAddress, longUserName, longEnglishUserName } from '../utilData';

const BOSS_EMPTY_CASE = '2';

const get: GetResponseResolver = async ({ params }) => {
  if (params.companyUserId === BOSS_EMPTY_CASE) {
    return HttpResponse.json(
      {
        answerers: [
          {
            companyUserId: 11,
            questionnaireAnswererId: 1,
            answererType: 'staff',
            name: '徳野純一',
            email: 'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz@example.com',
          },
        ],
      },
      { status: 200 }
    );
  }

  const answerers = [
    {
      companyUserId: 11,
      questionnaireAnswererId: 1,
      answererType: 'boss',
      name: longEnglishUserName,
      email: 'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz@example.com',
    },
    {
      companyUserId: 12,
      questionnaireAnswererId: 2,
      answererType: 'staff',
      name: longUserName,
      email: longMailAddress,
    },
    {
      companyUserId: 99,
      questionnaireAnswererId: 2,
      answererType: 'boss',
      name: '削除されたユーザー',
      email: null,
    },
    {
      companyUserId: 13,
      questionnaireAnswererId: 3,
      answererType: 'boss',
      name: '中島裕介',
      email: 'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz@example.com',
    },
    {
      companyUserId: 14,
      questionnaireAnswererId: 4,
      answererType: 'staff',
      name: '辰巳優斗',
      email: 'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz@example.com',
    },
    {
      companyUserId: 15,
      questionnaireAnswererId: 5,
      answererType: 'boss',
      name: '酒井英佑',
      email: 'sakai@example.com',
    },
  ];

  Array.from({ length: 1000 }, (_, index) =>
    answerers.push({
      companyUserId: index,
      questionnaireAnswererId: index,
      answererType: 'boss',
      name: `ユーザー${index + 1}`,
      email: `test${index + 1}@example.com`,
    })
  );

  answerers.forEach((a, index) => {
    a.questionnaireAnswererId = index + 2000;
    a.companyUserId = index + 2000;
  });

  return HttpResponse.json({
    answerers,
  });
};

const post: PostResponseResolver = async ({ params, request }) => {
  const companyUserId = params.companyUserId;
  const body = await request.json();

  if (companyUserId === '500') {
    return HttpResponse.json({}, { status: 500 });
  }

  const answerers = body.answerer_company_user_ids.map((id: number) => {
    return {
      companyUserId: id,
      questionnaireAnswererId: id,
      answererType: body.answerer_type,
      name: `追加されたユーザー(${id})`,
      email: 'aaaaa@example.com',
    };
  });

  return HttpResponse.json({
    answerers,
  });
};

const deleteMethod: DeleteResponseResolver = async () => {
  return HttpResponse.json({}, { status: 200 });
};

export const surveyQuestionnaireSettingAnswerTargetUsersAnswerers = {
  get,
  post,
  delete: deleteMethod,
};
