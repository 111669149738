export const SURVEY = {
  SETTING_INCOMPLETE: '2',
  SETTING_COMPLETE: '3',
  IN_PROGRESS: '4',
  COMPLETE: '5',
  BEFORE_CALCULATE: '6',
  CALCULATING: '7',
  CALCULATED_UNEXISTS_SUMMARY_ZIP: '8',
  CALCULATED_EXISTS_SUMMARY_ZIP: '9',
  SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_ERROR: '10',
  SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_WARNING: '11',
  SETTING_INPROGRESS_FOR_CONFIG_DISCARD_ERROR: '12',
  INPROGRESS_FOR_RESEND_MAIL: '13',
  PASSED_START_TIME: '14',
  IN_PROGRESS_BUSINESS_ATTITUDES: '15',
  BEFORE_START_ANSWER_FULL_ADDITIONAL_QUESTION: '16',
  AFTER_START_ANSWER_FULL_ADDITIONAL_QUESTION: '17',
};

export const STRETCH_PLAN = {
  SETTING_INCOMPLETE: '2',
  IN_PROGRESS_CASE: '3',
  PASSED_START_TIME: '4',
  COMPLETE: '5',
  INPROGRESS_FOR_RESEND_MAIL: '6',
};
