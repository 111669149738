import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { longMailAddress, longEnglishUserName } from '../utilData';

const NOT_FOUND_CASE = '404';
const LONG_CASE = '2';

const get: GetResponseResolver = async ({ params }) => {
  if (params.companyId === NOT_FOUND_CASE) {
    return HttpResponse.json({}, { status: 404 });
  }

  if (params.companyId === LONG_CASE) {
    return HttpResponse.json({
      name: longEnglishUserName,
      email: longMailAddress,
    });
  }

  return HttpResponse.json({
    name: '今井恭平',
    email: 'imai@example.com',
  });
};

export const companyUser = {
  get,
};
