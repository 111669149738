import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { longMailAddress, longUserName, longEnglishUserName } from '../utilData';
import type { AnswerTargetUsersAPI } from '~operation/types/api/companies/operationCycles/surveys/questionnaireSetting/answerTargetUsers';
const EMPTY_CASE = '2';

const get: GetResponseResolver = async ({ params }) => {
  if (params.surveyId === EMPTY_CASE) {
    return HttpResponse.json(
      {
        answerTargetUsers: [],
      },
      { status: 200 }
    );
  }

  const answerTargetUsers: AnswerTargetUsersAPI.AnswerTargetUser[] = [
    {
      companyUserId: 1,
      name: longEnglishUserName,
      email: 'long@example.com',
      bossAnswererCount: 99999,
      staffAnswererCount: 99999,
    },
    {
      companyUserId: 1,
      name: longUserName,
      email: longMailAddress,
      bossAnswererCount: 99999,
      staffAnswererCount: 99999,
    },
    {
      companyUserId: 2,
      name: '今井恭平',
      email: 'imai@example.com',
      bossAnswererCount: 1,
      staffAnswererCount: 2,
    },
    {
      companyUserId: 3,
      name: '植田健人',
      email: 'edasan@example.com',
      bossAnswererCount: 10,
      staffAnswererCount: 3,
    },
  ];

  Array.from({ length: 1000 }, (_, index) =>
    answerTargetUsers.push({
      companyUserId: index,
      name: `ユーザー${index + 1}`,
      email: `test${index + 1}@example.com`,
      bossAnswererCount: index,
      staffAnswererCount: index,
    })
  );

  answerTargetUsers.push({
    companyUserId: 99,
    name: '削除されたユーザー',
    email: null,
    bossAnswererCount: 99999,
    staffAnswererCount: 99999,
  });

  answerTargetUsers.forEach((a, index) => (a.companyUserId = index));

  return HttpResponse.json({
    answerTargetUsers,
  });
};

export const surveyQuestionnaireSettingAnswerTargetUsers = {
  get,
};
